import React from "react"
import { Link, navigate } from "gatsby"

// import footerform from '../img/footer-form.png'
// import footernews from '../img/footer-news.png'
import "./Styles/Footer.scss"
import { Timeline } from "react-twitter-widgets"
import CookieBanner from "react-cookie-banner/lib"

import img1 from "../img/BBC-5050-logo.png"
import img2 from "../img/dpp-logo.png"
import img3 from "../img/srt-alliance-logo.png"

const styles = {
  banner: {
    height: 57,
    background: "rgba(52, 64, 81, 0.88) url(/cookie.png) 20px 50% no-repeat",
    backgroundSize: "30px 30px",
    backgroundColor: "",
    fontSize: "15px",
    fontWeight: 600,
    position: "fixed",
    bottom: 0,
  },
  button: {
    border: "1px solid white",
    borderRadius: 4,
    width: 66,
    height: 32,
    lineHeight: "32px",
    background: "transparent",
    color: "white",
    fontSize: "14px",
    fontWeight: 600,
    opacity: 1,
    right: 80,
    marginTop: -18,
  },
  message: {
    display: "block",
    padding: "9px 67px",
    lineHeight: 1.3,
    textAlign: "left",
    marginRight: 244,
    color: "white",
  },
  link: {
    textDecoration: "none",
    fontWeight: "bold",
  },
}

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

class Footer extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isValidated: false }
  }

  componentDidMount() {
    // window._mfq = window._mfq || [];
    // var mf = document.createElement("script");
    // mf.type = "text/javascript";
    // mf.async = true;
    // mf.src =
    //   "//cdn.mouseflow.com/projects/a9738308-ee22-4c4b-b404-97a155bbd345.js";
    // document.getElementsByTagName("head")[0].appendChild(mf);
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch((error) => alert(error))
  }

  render() {
    return (
      <React.Fragment>
        <CookieBanner
          styles={styles}
          dismissOnScroll={false}
          message="Globelynx sets cookies on your device to help us improve our site. To learn more about what data we collect and your privacy options,"
          link={
            <a href="https://pamediagroup.com/privacy-policy/" target="_blank">
              see our privacy policy.
            </a>
          }
        />
        {/* 
        <CookieBanner
          styles={styles}
          message="Globelynx sets cookies on your device to help us improve our site. To learn more about what data we collect and your privacy options, "
          link={<a href='http://nocookielaw.com/'>see our privacy policy.</a>}
          buttonMessage='Close'
          dismissOnScroll={false}
          cookie="user-has-accepted-cookies" /> */}

        <footer
          className={`${
            this.props.location === "dashboard"
              ? "is-display-none"
              : "is-fullwidth has-background-blue"
          }`}
        >
          <div className="container-fluid">
            {/* <div className="row">
              <div className="twelve columns">
                <h2
                  className=" has-text-centered custom-heading"
                  style={{ color: "#fff", marginBottom: 0 }}
                >
                  CONTACT
                </h2>
              </div>
            </div> */}
            <div className="row">
              <div className="six columns">
                <ul className="footer-lists" style={{ padding: 0 }}>
                  <li>
                    <Link to="/experts">Experts</Link>
                  </li>
                  <li>
                    <Link to="/about">About</Link>
                  </li>
                  <li>
                    <Link to="https://news.globelynx.com/">News</Link>
                  </li>
                  <li>
                    <Link to="/cameras">Globelynx Cameras</Link>
                  </li>
                  <li>
                    <Link to="/products">Services</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>
                  <li>
                    <Link to="/tv-interview-training-with-globelynx">
                      Training
                    </Link>
                  </li>

                  <li>
                    <a target="_blank" href="https://pamediagroup.com/">
                      {" "}
                      Proudly Part of PA Media Group
                    </a>
                  </li>

                  {/* <li>
                    <Link to="/">Privacy Policy and Terms and Condition</Link>
                  </li> */}
                </ul>
                <a
                  target="_blank"
                  href="https://www.bbc.co.uk/5050/ourpartners"
                >
                  <img
                    style={{
                      margin: 10,
                      marginRight: 10,
                      height: 50,
                      width: "auto",
                    }}
                    src={img1}
                  />
                </a>
                <a target="_blank" href="https://www.thedpp.com/membership">
                  <img
                    style={{
                      margin: 10,
                      marginRight: 10,
                      height: 50,
                      width: "auto",
                    }}
                    src={img2}
                  />
                </a>
                <a target="_blank" href="https://www.srtalliance.org/members/">
                  <img
                    style={{
                      margin: 10,
                      marginRight: 10,
                      height: 50,
                      width: "auto",
                    }}
                    src={img3}
                  />
                </a>
              </div>
              <div className="six columns" style={{ textAlign: "center" }}>
                {/* <Timeline
                  dataSource={{
                    sourceType: "profile",
                    screenName: "Globelynx",
                  }}
                  options={{
                    username: "Globelynx",
                    height: "300",
                    width: "500",
                  }}
                /> */}
              </div>
              <div className="six columns">
                {/* <form
                  name="contact"
                  method="post"
                  action="/contact/thanks/"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                  onSubmit={this.handleSubmit}
                >
                  <input type="hidden" name="form-name" value="contact" />
                  <div hidden>
                    <label>
                      Don’t fill this out:{" "}
                      <input name="bot-field" onChange={this.handleChange} />
                    </label>
                  </div>
                  <div className="footer-form-container">
                    <div className="six columns">
                      <input
                        type="text"
                        className="footer-form-input is-fullwidth"
                        placeholder="Name*"
                        name={"name"}
                        onChange={this.handleChange}
                        id={"name"}
                        required={true}
                      />
                      <input
                        type="email"
                        className="footer-form-input is-fullwidth"
                        placeholder="Email*"
                        name={"email"}
                        onChange={this.handleChange}
                        id={"email"}
                        required={true}
                      />
                      <input
                        type="number"
                        className="footer-form-input is-fullwidth"
                        placeholder="Telephone*"
                        name={"phone"}
                        onChange={this.handleChange}
                        id={"name"}
                        required={true}
                      />
                    </div>
                    <div className="six columns">
                      <textarea
                        className="footer-form-textarea is-fullwidth"
                        placeholder="Message*"
                        name={"message"}
                        onChange={this.handleChange}
                        id={"message"}
                        required={true}
                      />
                      <button
                        className="is-fullwidth footer-form-submit"
                        type="submit"
                      >
                        Send
                      </button>
                    </div>
                    <div className="footer-link-container">
                      <a
                        href="mailto:support@globelynx.com"
                        className="footer-a-link"
                      >
                        <i className="fa fa-envelope footer-form-icon" />
                        <span className="footer-icon-span">
                          support@globelynx.com
                        </span>
                      </a>
                      <a href="tel:442079637061" className="footer-a-link">
                        <i
                          className="fa fa-phone footer-form-icon"
                          style={{ transform: "rotate(90deg)" }}
                        />
                        <span className="footer-icon-span">
                          +44-207-963-7061
                        </span>
                      </a>
                    </div>
                  </div>
                </form> */}
              </div>
            </div>
          </div>
        </footer>
      </React.Fragment>
    )
  }
}

export default Footer
